// Defaults
$primary-color: #a00;
$secondary-color: #f5f5f5;
$white: #fff;

// We define the mixins for two major breakpoints mobile and tablet

 // $breakpoint is the variable that can have several values
@mixin devices ($breakpoint) { //the name of the mixin is devices

    @if $breakpoint == tablet {
    @media only screen and (max-width: 680px) {
      @content;
    }
  }

  @if $breakpoint == mobile {
    @media only screen and (max-width: 481px) {
      @content;
    }
  }
}

* {
}

@include devices(tablet) {
  li {
    font-size: x-large !important;
  }
}

@include devices(mobile) {
  li {
    font-size: x-large !important;

    h1 {
      font-size: 1rem;
    }
  }
}

// Global reuse
.brand-colored {
  background-color: $primary-color;
  color: $secondary-color;

  a {
    color: $secondary-color;
    text-decoration: none;
  }
}

// Header
header {
  nav {
    .navbar-brand {
      img {
        background-color: $secondary-color;
      }

      .nav-text {
        color: $secondary-color;
        margin-left: 0.5rem;
        display: inline-block;
        vertical-align: middle;

        #site-title {
          font-size: 2.5rem;
        }

        #site-tagline {
          font-size: 1rem;
          font-style: italic;
        }
      }
    }

    .navbar-toggler {
      background-color: $secondary-color;
    }

    .navbar-nav {
      .nav-item {
        text-align: center;

        .nav-link {
          color: $secondary-color;
          padding: 1rem;
        }

        ul.dropdown-menu {
          background-color: $primary-color;

          li {
            text-align: center;

            .dropdown-item {
              color: $secondary-color;
            }

            a:hover {
              background-color: $primary-color;
            }
          }
        }
      }
    }
  }
}

// Alert (if needed)
#alert {
  #alert-content {
    border: 2px solid $primary-color;
    margin: 0.5rem 0 2rem;

    #alert-title {
      font-size: 2rem;
      text-align: center;
      text-decoration: underline;
      margin-bottom: 0.5rem;
    }
  }
}

// Main content
main {
  h1 {
    font-size: 3rem;
    margin-bottom: 1.5rem;
  }

  #tagline {
    font-size: 1.25rem;
    margin-bottom: 1.5rem;
  }

  #what-to-expect {
    font-size: 1.25rem;
    margin-bottom: 1rem;
  }

  #customer-testimonial {
    font-size: 1rem;
    font-style: italic;
    margin: 1rem 0 0.5rem;
  }
}


// FAQ
.q-and-a {
  margin-bottom: 1rem;

  .faq-question {
    font-style: italic;
  }

  .faq-answer {
    font-weight: bold;
  }
}

// Contact
#contact {
  a {
    color: $primary-color;
    text-decoration: none;
  }
}

// Footer
footer {
  background-color: $primary-color;
  text-align: center;

  #call-for-service {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
}
